export default {
  props: {
    acceptImg: {
      type: String,
    },
    multipleImg: {
      type: Boolean,
    },
    sId: {
      type: String,
    },
    iTotalImages:{
    type:Number,
    }
  },
  data() {
    return {
      filelist: [],
      texts: ''
    };
  },
  mounted() {
    this.texts = FILE.uploadTexts[this.selectLanguage];
  },
  methods: {
    UrlImg(file){
    return URL.createObjectURL(file)
    },
    clear() {

      this.filelist = [];
      this.$emit("sendImages", this.filelist);

      //   document.getElementById(this.sId).value = "";
      // if (document.getElementById(this.sId).value !== null) {
      //   }
    },
    formatMb(size) {
      let format_size = 0;
      format_size = (size / 1000) / 1000;
      return format_size.toFixed(2);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      if (this.$refs.file.files.length > 1) {
      }else{
        this.onChange(); // Trigger the onChange event manually
      }
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },

    onChange(event) {
      let inp = document.getElementById(this.sId);
      let getMultiple = inp.getAttribute("multiple");

      if (getMultiple !== 'multiple') {
        this.filelist = [...this.$refs.file.files];
        this.$emit("sendImages", this.filelist);
      } else {
        this.filelist.push(...this.$refs.file.files);
        this.$emit("sendImages", this.filelist);
      }
      // alert(URL.createObjectURL(...this.$refs.file.files))
      
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.$emit("sendImages", this.filelist);
      // document.getElementById(this.sId).value = "";
      // if (document.getElementById(this.sId).value !== null) {
      // }
      
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.uploadTexts[this.selectLanguage];
      }
    },
   
  }
};